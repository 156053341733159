<template>
  <div>
    <vx-card actionable class="cardx" title="">
      <vs-row type="flex" style="margin-top: 2%">
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1.5">
          <vs-button
            class="rounded-r-none p-3 md:px-8 md:py-3"
            @click="cmTeam('CM')"
            :color="colorx"
            :style="cmTeamSelectButtonColor"
            size="small"
            type="border"
            >CM</vs-button
          >
          <vs-button
            class="rounded-l-none p-3 md:px-8 md:py-3"
            @click="exedTeam('ExEd')"
            :color="colorx"
            :style="exedTeamSelectButtonColor"
            size="small"
            type="border"
            >ExEd</vs-button
          >
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <v-select
            v-if="selectedTeam === 'CM'"
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
           <v-select
            v-if="selectedTeam === 'ExEd'"
            label="ExEd sub teams"
            :options="exed_cities"
            v-model="selected_exed_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <v-select
            v-if="selectedTeam === 'CM'"
            label="City classification"
            :options="city_classification_list"
            v-model="selected_city_classification"
            style="width: 240px; z-index: 1000"
          ></v-select>
          <v-select
            v-if="selectedTeam === 'ExEd'"
            label="City classification"
            :options="exed_city_classification_list"
            v-model="selected_exed_city_classification"
            style="width: 240px; z-index: 1000"
          ></v-select>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5">
          <treeselect openDirection='bottom' :multiple="true"  style="width:100%;"
            :limit="3"  :value-consists-of="'LEAF_PRIORITY'"   :options="ne_courses" placeholder="NE Course" v-model="selected_course" />
        </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.75">
            <treeselect openDirection='bottom' :multiple="true"  style="width:100%;"
            :limit="3"  :value-consists-of="'LEAF_PRIORITY'"   :options="ne1_sources" placeholder="NE Bucket 1 Source" v-model="ne_bucket1_source" />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.75">
            <treeselect openDirection='bottom' :multiple="true"  style="width:100%;"
            :limit="3"  :value-consists-of="'LEAF_PRIORITY'"   :options="ne2_sources" placeholder="NE Bucket 2 Source" v-model="ne_bucket2_source" />
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <date-range-picker ref="picker" :opens="'left'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
            }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
            :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="dateRange"
            style="width: 240px; margin-right: 2%"></date-range-picker>
          </vs-col> -->
          <!-- <vs-col
            v-if="selectedTeam === 'CM'"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="0.5"
          >
          <vs-button
            color="dark"
            type="filled"
            @click="getSpocsWithCityClasification()"
          >GO</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
          <vs-button radius class="gap" color="dark" type="gradient" icon="refresh"
            @click="refreshFilterData"></vs-button>
          </vs-col> -->
         <vs-col
          v-if="selectedTeam === 'ExEd'"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-button
            color="dark"
            type="filled"
            @click="getExedSpocsWithCityClasification()"
            >GO</vs-button>
        </vs-col>
      </vs-row>
      <vs-row class="mt-6">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <date-range-picker ref="picker" :opens="'right'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
            }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
            :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="dateRange"
            style="width: 240px; margin-right: 2%"></date-range-picker>
          </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
          <vs-checkbox v-model="visit_scheduled" color="#0044BA"
            class="checkboxInput"> Visit Scheduled
          </vs-checkbox>
          </vs-col> -->
          <vs-col
            v-if="selectedTeam === 'CM'"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
          <vs-button
            color="dark"
            type="filled"
            @click="getSpocsWithCityClasification()"
          >GO</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
          <vs-button radius class="gap" color="dark" type="gradient" icon="refresh"
            @click="refreshFilterData"></vs-button>
          </vs-col>
      </vs-row>
      <vx-card actionable class="cardx" style="margin-top: 3%">
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
            style="font-size: 19px"
          >
            <b>{{ selectedTeam }} - Net Enquiries Transfer</b>
          </vs-col> </vs-row
        ><br />
        <vs-table :data="sortSpoc">
          <template slot="thead">
            <vs-th>Spoc&nbsp;Name</vs-th>
            <!-- <vs-th>Net Enquiries</vs-th> -->
            <vs-th>NE&nbsp;Bucket&nbsp;1</vs-th>
            <vs-th><span class="ml-6">Action</span></vs-th>
            <vs-th>NE&nbsp;Bucket&nbsp;2 </vs-th>
            <vs-th><span class="ml-6">Action</span></vs-th>
            <vs-th>MHP&nbsp;Called</vs-th>
            <vs-th><span class="ml-10">Action</span></vs-th>
            <vs-th>MHP&nbsp;Not&nbsp;Called</vs-th>
            <vs-th><span class="ml-10">Action</span></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].first_name">
                {{data[indextr].full_name}}  {{data[indextr].user_tag ? "-" : "" }} {{data[indextr].user_tag ? data[indextr].user_tag : ""}}</vs-td>
              <!-- <vs-td :data="data[indextr].first_name">
                {{ data[indextr].net_enquiries }}
              </vs-td> -->
              <vs-td style="text-align: center;" :data="data[indextr].first_name">
                {{ data[indextr].ne_bucket_1 }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                  <vs-button size="small" icon="edit" @click="editNe_1(data[indextr])" color="success"
                    ><span class="editTxt">NE&nbsp;1</span></vs-button
                  >
              </vs-td>
              <vs-td style="text-align: center;" :data="data[indextr].first_name">
                {{ data[indextr].ne_bucket_2 }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                  <vs-button size="small" small icon="edit" @click="editNe_2(data[indextr])" color="success"
                    ><span class="editTxt">NE&nbsp;2</span></vs-button
                  >
              </vs-td>
              <vs-td style="text-align: center;" :data="data[indextr].first_name">
                {{ data[indextr].mhp_called }}
              </vs-td>
              <vs-td>
              <vs-td :data="data[indextr].first_name">
              <vs-button size="small" icon="edit" @click="mhpCalledPopUp(data[indextr])" color="warning"
                ><span class="editTxt">Called</span></vs-button
              >
              </vs-td>
              </vs-td>
              <vs-td style="text-align: center;" :data="data[indextr].first_name">
                {{ data[indextr].mhp_not_called }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
              <vs-button size="small" icon="edit" @click="mhpNotCalledPopUp(data[indextr])" color="warning"
                ><span class="editTxt">Not&nbsp;Called</span></vs-button
              >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </vx-card>

    <vs-popup class="assignspocpopupmultiplewidthnet" :active.sync="netEnquiriesPopup" :title="editspoctitle">
      <div>
        <br/>
        <vs-row>
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>City :</b></vs-col>
          <vs-col  vs-w="8" vs-justify="center" vs-align="center">
            <v-select
              style="width:23%; position: fixed; z-index:1300"
              v-model="selected_spoc_city"
              :options="spoc_cities"
              placeholder="Cities"
            ></v-select>
          </vs-col>
        </vs-row><br/>
        <vs-row v-if="selectedTeam === 'CM'" style="margin-top: 1%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>User Tag :</b></vs-col>
          <vs-col  vs-w="8" vs-justify="center" vs-align="center">
            <v-select
              style="width:23%; position: fixed; z-index:1200"
              v-model="selected_spoc_user_tag"
              :options="spoc_user_tags"
              placeholder="User Tags"
            ></v-select>
          </vs-col>
        </vs-row>
        <br/>
        <vs-row style="margin-top: 1%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>Course :</b></vs-col>
          <vs-col vs-w="8" vs-justify="center" vs-align="center">
            <treeselect openDirection='bottom' :multiple="true"  style="width:23%;position:fixed; z-index:1100"
            :value-consists-of="'LEAF_PRIORITY'" :limit="3"   :options="ne_courses" placeholder="Course" v-model="selected_ne_course" />
          </vs-col>
        </vs-row><br/>
        <vs-row style="margin-top: 1%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>Source :</b></vs-col>
          <vs-col v-if="ne_bucket == 1" vs-w="8" vs-justify="center" vs-align="center">
            <treeselect openDirection='bottom' :multiple="true"  style="width:23%;position:fixed; z-index:1000"
            :value-consists-of="'LEAF_PRIORITY'" :limit="3"   :options="ne1_sources" placeholder="Sources" v-model="selected_ne1_source" />
          </vs-col>
          <vs-col v-else-if="ne_bucket == 2" vs-w="8" vs-justify="center" vs-align="center">
            <treeselect openDirection='bottom' :multiple="true"  style="width:23%;position:fixed; z-index:1000"
            :value-consists-of="'LEAF_PRIORITY'" :limit="3"  :options="ne2_sources" placeholder="Sources" v-model="selected_ne2_source" />
          </vs-col>
        </vs-row><br/>
        <vs-row style="margin-top: 5%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-input class="inputx" placeholder="Count" v-model="editNetEnquiriecount" />
          </vs-col>
          <vs-col vs-w="8"  vs-justify="center" vs-align="center">
            <!-- <v-select
              style="width:25%; position: fixed; z-index:900"
              :multiple="true"
              v-model="selectedColoumnSpoc"
              :options="selected_usertag_spocs"
              label="full_name"
              placeholder="Spocs"
            ></v-select> -->
            <!-- <vs-select
              style="width: 100%"
              v-model="selectedColoumnSpoc"
              placeholder="Spocs"
              multiple
            >
             <vs-select-item :key="index" :value="item" :text="item.full_name" v-for="(item,index) in selected_usertag_spocs" />
          </vs-select> -->
          <div>
              <treeselect openDirection='bottom' :multiple="true"  style="width:23%;position:fixed; z-index:900;"   :options="selected_usertag_spocs_tree_data" placeholder="Select Spoc" v-model="selectedColoumnSpoc" />
               <!-- <treeselect-value :value="selectedColoumnSpoc" /> -->
            </div>
          </vs-col>
           <vs-col vs-w="2" vs-justify="center" vs-align="center">

            <vs-button color="success" @click="transferNetEnquiries()" type="filled" style="display:flex;justify-content:center;width:120% !important"
              >Assgin</vs-button
            >
          </vs-col></vs-row
        >

        <br />
      </div>
    </vs-popup>
    <vs-popup class="assignspocpopupmultiplewidthnet" :active.sync="mhpnetEnquiriesPopup" :title="mhpeditspoctitle">
      <div>
        <br/>
        <vs-row>
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>City :</b></vs-col>
          <vs-col  vs-w="8" vs-justify="center" vs-align="center">
            <v-select
              style="width:23%; position: fixed; z-index:1100"
              v-model="selected_spoc_city"
              :options="spoc_cities"
              placeholder="Cities"
            ></v-select>
          </vs-col>
        </vs-row><br/>
        <vs-row v-if="selectedTeam === 'CM'" style="margin-top: 1%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>User Tag :</b></vs-col>
          <vs-col  vs-w="8" vs-justify="center" vs-align="center">
            <v-select
              style="width:23%; position: fixed; z-index:1000"
              v-model="selected_spoc_user_tag"
              :options="spoc_user_tags"
              placeholder="User Tags"
            ></v-select>
          </vs-col>
        </vs-row>
        <br/>
        <vs-row style="margin-top: 1%">
          <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-input
              class="inputx"
              placeholder="Count"
              v-model="mhpeditNetEnquiriecount"
              style="text-align:center"
            />
          </vs-col>
          <vs-col vs-w="8"  vs-justify="center" vs-align="center">

            <!-- <v-select
              style="width:25%; position: fixed; z-index:900"
              :multiple="true"
              v-model="mhpselectedColoumnSpoc"
              :options="selected_usertag_spocs"
              label="full_name"
              placeholder="Spocs"
            ></v-select> -->
            <div>
              <treeselect openDirection='bottom' :multiple="true"  style="width:23%;position:fixed; z-index:900"   :options="selected_usertag_spocs_tree_data" placeholder="Select Spoc" v-model="mhpselectedColoumnSpoc" />
               <!-- <treeselect-value :value="mhpselectedColoumnSpoc" /> -->
            </div>
            <!-- <vs-select
              style="width: 100%"
              v-model="mhpselectedColoumnSpoc"
              placeholder="Spocs"
              multiple
            >
             <vs-select-item :key="index" :value="item" :text="item.full_name" v-for="(item,index) in selected_usertag_spocs" />
          </vs-select> -->
          </vs-col>
          <vs-col vs-w="2" vs-justify="center" vs-align="center">
            <vs-button color="success" @click="transferMHPNetEnquiries()" type="filled" style="display:flex;justify-content:center;width:120% !important"
              >Assgin</vs-button
            >
          </vs-col> </vs-row
        ><br />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DateRangePicker from "vue2-daterange-picker";
// import eventbus from "../components/eventbus";
export default {
  mounted() {
    this.spoc_id = localStorage.getItem("spoc_id");
    console.log("hjgfghfughfui",this.spoc_id);
      this.spocscopy = [];
      this.cities = [];
      this.spocs = [];
      console.log(this.selectedTeam,"team");
      this.getSpocCities();
      this.getAllSpocWithTeams(this.selectedTeam);
      // this.getSpocs(this.selectedTeam);
      if (this.team == "CM") {
        this.getSpocCities();
      } else if (this.team == "Exed") {
        this.getExedSpocCities();
      }
  },
  data() {
    return {
      selected_usertag_spocs_tree_data: [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      dateRange: {
        startDate: '',
        endDate: '',
      },
      selected_usertag_spocs: [],
      selected_spoc_user_tag: "",
      selected_spoc_city: "",
      spoc_user_tags: [],
      spoc_cities: [],
      spoc_id: "",
      selected_city_classification: "",
      city_classification_list: [],
      from_spoc_name:"",
      iiml_spocs: [],
      team_select: this.team,
      colorx: "rgb(199, 42, 117)",
      editspoctitle: "",
      mhpeditspoctitle: "",
      netEnquiriesPopup: false,
      mhpnetEnquiriesPopup: false,
      mhpeditNetEnquiriecount: "",
      editNetEnquiriecount: "",
      selectedColoumnSpoc: [],
      mhpselectedColoumnSpoc: [],
      mhp_iiml_spocs: [],
      cities: [],
      ne_courses: [
        {
            id: "All",
            label: "All",
            children: [
              { id: "CPA", label: "CPA" },
              { id: "CMA", label: "CMA" },
              { id: "CFA", label: "CFA" },
              { id: "FRM", label: "FRM" },
              { id: "USP", label: "USP" },
            ]
        }
      ],
      selected_course: [],
      exed_cities: [],
      selected_exed_city: "",
      exed_city_classification_list: [],
      selected_exed_city_classification: "",
      ne_bucket1_source: [],
      ne_bucket2_source: [],
      selected_ne_course: [],
      selected_ne1_source: [],
      selected_ne2_source: [],
      visit_scheduled: false,
      ne1_sources: [
        {
            id: "B1",
            label: "B1",
            children: [
              { id: "Google", label: "Google" },
              { id: "Miles Website", label: "Miles Website" },
              { id: "LinkedIn", label: "LinkedIn" },
              { id: "USP website", label: "USP website" },
              { id: "Website", label: "Website" },
              { id: "SuperBot", label: "SuperBot" },
              { id: "WhatsApp", label: "WhatsApp" },
              { id: "YouTube", label: "YouTube" },
            ]
        }
      ],
      ne2_sources: [
          {
            id: "B2",
            label: "B2",
            children: [
              { id: "Webinar", label: "Webinar" },
              { id: "CA Data", label: "CA Data" },
              { id: "Placement Drive", label: "Placement Drive" },
              { id: "Meta", label: "Meta" },
              { id: "Naukri", label: "Naukri" },
              { id: "Li_inmails", label: "Li_inmails" },
              { id: "Email", label: "Email" },
              { id: "Socials", label: "Socials" },
              { id: "Business Partner", label: "Business Partner" },
              { id: "Voice SMS", label: "Voice SMS" },
              { id: "YouTube", label: "YouTube" },
              { id: "Live Chat", label: "Live Chat" },
              { id: "Chat Enquiries", label: "Chat Enquiries" },
              { id: "gupshup", label: "gupshup" },
              { id: "Organic", label: "Organic" },
              { id: "LMS", label: "LMS" },
            ]
          }
      ],
      sortSpoc: [],
      spocs: [],
      selected_spoc: "",
      selected_city: "",
      cityString: "",
      SpocId: "",
      SpocName: "",
      spocsByCity: [],
      allSpocs: [],
      from_spoc: "",
      ne_selected_spoc_list: [],
      mhp_selected_spocid_list: [],
      mhp_selected_spoc_firstname_list: [],
      count: "",
      selectedTeam: "CM",
      cmTeamSelectButtonColor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      exedTeamSelectButtonColor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      roles: ["IIML-PM", "IIML-HR", "IITR-BF", "IIML-BA"],
      ne_bucket: '',
      mhp_type: '',
    };
  },
  components: {
    "v-select": vSelect,
    DateRangePicker,
    Treeselect
  },
  watch: {
    selected_course(val){
      console.log('selected_course' ,val);
    },
    ne_bucket1_source(val){
      console.log('ne_bucket1_source' ,val);
    },
    ne_bucket2_source(val){
      console.log('ne_bucket2_source' ,val);
    },
    selected_ne1_source(val){
      console.log('selected_ne1_source' ,val);
    },
    selected_ne2_source(val){
      console.log('selected_ne2_source' ,val);
    },
    selected_ne_course(val){
      console.log('selected_ne_course' ,val);
    },
    selected_spoc_city(val){
      if(this.selectedTeam == 'CM'){
        this.selected_spoc_user_tag = "";
        let selected_user_tag_spocs = [];
        for (let i = 0; i < this.iiml_spocs.length; i++) {
          const element = this.iiml_spocs[i];
          if(element.city === val){
            selected_user_tag_spocs.push(element);
          }
        }
        this.spoc_user_tags = [
          ...new Set(selected_user_tag_spocs.map((item) => item.user_tag)),
        ];
        console.log("this.spoc_user_tags",this.spoc_user_tags);
      }
      if(this.selectedTeam == 'ExEd'){
        this.selectedColoumnSpoc = [];
        this.mhpselectedColoumnSpoc = [];
        let selected_subteam_exed_spocs = [];
        for (let i = 0; i < this.iiml_spocs.length; i++) {
          const element = this.iiml_spocs[i];
          if(element.sub_team == val){
            selected_subteam_exed_spocs.push(element);
          }
        }
        console.log("selected_subteam_exed_spocs",selected_subteam_exed_spocs);
        this.selected_usertag_spocs_tree_data = [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ];
      selected_subteam_exed_spocs.forEach((spoc) => {
        console.log(spoc);
        this.selected_usertag_spocs_tree_data.forEach((bde) => {
          var obj = {
                      id: spoc.id,
                      label: spoc.full_name,
                    };
            bde.children.push(obj);
          });
        });
        console.log("selected_usertag_spocs_tree_data*******2",this.selected_usertag_spocs_tree_data);
      }
    },
    selected_spoc_user_tag(val){
      if(this.selectedTeam == 'CM'){
        this.selectedColoumnSpoc = [];
        this.mhpselectedColoumnSpoc = [];
        this.selected_usertag_spocs = [];
        for (let i = 0; i < this.iiml_spocs.length; i++) {
          const element = this.iiml_spocs[i];
          if(element.user_tag === val && element.city == this.selected_spoc_city){
            this.selected_usertag_spocs.push(element);
          }
        }
        console.log("selected_usertag_spocs********1",this.selected_usertag_spocs);
        this.selected_usertag_spocs_tree_data = [
          {
            id: "All",
            label: "All",
            children: [],
          }
        ];
        this.selected_usertag_spocs.forEach((spoc) => {
          console.log(spoc);
          this.selected_usertag_spocs_tree_data.forEach((bde) => {
            var obj = {
                    id: spoc.id,
                    label: spoc.full_name,
                  };
              bde.children.push(obj);
            });
          });
          console.log("selected_usertag_spocs_tree_data*******2",this.selected_usertag_spocs_tree_data);
      }

    },
    selected_exed_city: function (value) {
      this.getExedCityClasificationCodes(value);
    },
    selected_exed_city_classification(val){
      this.selected_exed_city_classification = val;
      // this.getExedSpocsWithCityClasification();
    },
    selected_city: function (value) {
      if (value !== null || value !== "") {
        console.log("spoc in selected city",value);
        this.getCityClasificationCodes(value);
        // this.sortSpocs(value);
      } else {
        this.sortSpoc = [];
      }
    },
    selected_city_classification(val){
      this.selected_city_classification = val;
      // this.getSpocsWithCityClasification();
    },
  },
  methods: {
    getSpocCities() {
      let url = `${constants.SERVER_API}getSpocCities`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getSpocCities reponse",response)
          let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
            this.cities = [];
            let citys = [
              ...new Set(response.data.map((item) => item)),
            ];
            citys.forEach(element => {
            if(city_options.includes(element)){
              this.cities.push(element);
            }
          })
          // this.cities = this.sortArrayAlphabetically(response.data);
          console.log("getSpocCities", this.cities);
          if (localStorage.getItem("role") === "cm4") {
            this.selected_city = this.cities[0];
        // this.selected_city = this.roles[0];
          } else if (localStorage.getItem("role") === "exed2"){
            this.selected_city = this.cities[0];
          }
          else if (localStorage.getItem("role") === "cm1") {
            this.SpocName.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
          });
          }
          // this.extractSpocByCity(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    cmTeam(team) {

      this.exedTeamSelectButtonColor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.cmTeamSelectButtonColor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.cities = [];
      console.log("team", team);
      this.selectedTeam = team;
      this.getSpocCities();
      this.getAllSpocWithTeams(team);
    },
    exedTeam(team) {
      console.log("dgydg", team);
      this.cmTeamSelectButtonColor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.exedTeamSelectButtonColor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.exed_cities = [];
      this.selectedTeam = team;
      this.getExedSpocCities();
      console.log("team", team);
      this.getAllSpocWithTeams(team);

    },
    getExedSpocCities() {
      let url = `${constants.SERVER_API}getExedSpocCities`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getExedSpocCities",response)
          this.loggedin = response.data.logged_in_user_id;
           response.data = this.sortArrayAlphabetically(response.data);
          console.log("getExedSpocCities length",  response.data );
          for (let i = 0; i < response.data.length; i++) {
            const exed_city = response.data[i];
             if (exed_city != "IIML-FA" && exed_city != "IIML-SF" && exed_city != "IIML-FT" && exed_city != "null") {
                this.exed_cities.push(exed_city);
            }
          }
            this.selected_exed_city =  this.exed_cities[0];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCityClasificationCodes(city) {
      this.$vs.loading();
      let url = `${constants.SERVER_API}getCityClasificationCodes?city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log("getCityClasificationCodes", response);

          this.city_classification_list = this.sortArrayAlphabetically(response.data);
          if(this.spoc_id == 132 ||this.spoc_id == 937 || this.spoc_id == 39 || this.spoc_id == 93 || this.spoc_id == 729){
             this.city_classification_list;
            }
            else{
              this.city_classification_list = this.city_classification_list.filter(item => item !== 'X');
   
           }
          if (localStorage.getItem("role") === "cm4") {
            this.selected_city_classification = this.city_classification_list[0];
          } else if (localStorage.getItem("role") === "exed2"){
            this.selected_city_classification = this.city_classification_list[0];
          }
          else if (localStorage.getItem("role") === "cm1") {
            this.SpocName.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            this.selected_city_classification = spoc.city_classification;
          }
          });
          }
          console.log(
            this.city_classification_list,
            "city_classification_list"
          );
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getExedCityClasificationCodes(city) {
      this.$vs.loading();
      let url = `${constants.SERVER_API}getExedCityClasificationCodes?city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log("getCityClasificationCodes", response);

          this.exed_city_classification_list = this.sortArrayAlphabetically(
            response.data
          );
          this.selected_exed_city_classification =  this.exed_city_classification_list[0];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocsWithCityClasification() {
      // let city = this.new_selected_city;
      console.log(this.selected_city);
      console.log(this.selected_city_classification);
      let url = `${constants.SERVER_API}getSpocsWithCityClasification?city=${this.selected_city}&city_clasification=${this.selected_city_classification}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getSpocsWithCityClasification", response);

          // this.selected_spocs = response.data.spocs;
          // console.log("step1", this.selected_spocs);
          this.loggedin = response.data.logged_in_user_id;
          // response.data.spocs = this.removeUnwwantSpocs(response.data.spocs);
          this.SpocName = response.data.spocs;
          // this.extractSpocByCity();
          // this.getSpocIds(spoc_data);

          this.assignLead(this.SpocName, this.selectedTeam, this.ne_bucket1_source, this.ne_bucket2_source, this.selected_course, this.visit_scheduled);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getExedSpocsWithCityClasification() {
      // let city = this.new_selected_city;
      console.log(this.selected_city);
      console.log(this.selected_city_classification);
      let url = `${constants.SERVER_API}getExedSpocsWithCityClasification?city=${this.selected_exed_city}&city_clasification=${this.selected_exed_city_classification}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getExedSpocsWithCityClasification", response);


          // this.selected_spocs = response.data.spocs;
          // console.log("step1", this.selected_spocs);
          this.loggedin = response.data.logged_in_user_id;
          // response.data.spocs = this.removeUnwwantSpocs(response.data.spocs);
          this.SpocName = response.data.spocs;
          // this.extractSpocByCity();
          // this.getSpocIds(spoc_data);

          this.assignLead(this.SpocName, this.selectedTeam, this.ne_bucket1_source, this.ne_bucket2_source, this.selected_course, this.visit_scheduled);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editNe_1(selectedSpocData) {
      this.ne_bucket = 1;
      console.log("ryrtyrty",selectedSpocData);
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.selectedColoumnSpoc = [];
      this.selected_ne1_source = [];
      this.selected_ne2_source = [];
      this.selected_ne_course = [];
      this.editNetEnquiriecount = "";
      this.from_spoc = selectedSpocData.id;
      console.log("selectedSpocData", selectedSpocData);
      this.editspoctitle = "NE Bucket 1" + " " + "-" + " " +
        selectedSpocData.first_name +
        ",  " +
        selectedSpocData.city +
        ",    Total : " +
        selectedSpocData.ne_bucket_1;
      //   this.editNetEnquiriecount = selectedSpocData.net_enquiries_sorted.length
      this.netEnquiriesPopup = true;
    },
    editNe_2(selectedSpocData) {
      this.ne_bucket = 2;
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.selectedColoumnSpoc = [];
      this.selected_ne2_source = [];
      this.selected_ne1_source = [];
      this.selected_ne_course = [];
      this.editNetEnquiriecount = "";
      this.from_spoc = selectedSpocData.id;
      console.log("selectedSpocData", selectedSpocData);
      this.editspoctitle = "NE Bucket 2" + " " + "-" + " " +
        selectedSpocData.first_name +
        ",  " +
        selectedSpocData.city +
        ",    Total : " +
        selectedSpocData.ne_bucket_2;
      //   this.editNetEnquiriecount = selectedSpocData.net_enquiries_sorted.length
      this.netEnquiriesPopup = true;
    },
    getAllSpocWithTeams(team) {
      let url = `${constants.SERVER_API}getAllSpocWithTeams?team=${team}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllSpocWithTeams", response);
          this.iiml_spocs = [];
          response.data.spocs.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} - ${
              spoc.user_tag ? spoc.user_tag : " "
            } - ${spoc.city}`;
            if (spoc.assign_net_enquiry === 1 || spoc.assign_net_enquiry === 0) {
              this.iiml_spocs.push(spoc);
            }
          });
          // this.getCitiesAndUsertag(this.iiml_spocs);
          if(this.selectedTeam === 'CM'){
            this.getCitiesAndUsertag(this.iiml_spocs);
          }
          if(this.selectedTeam === 'ExEd'){
            this.spoc_cities = this.exed_cities;
          }
          // this.iiml_spocs = this.sortArrayAlphabetically(this.iiml_spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCitiesAndUsertag(spocs){
      this.spoc_cities = [];
      this.spoc_cities = [
        ...new Set(spocs.map((item) => item.city)),
      ];
      console.log("city filter",this.spoc_cities);
      // this.spoc_user_tags = [
      //   ...new Set(spocs.map((item) => item.user_tag)),
      // ];
    },
    mhpCalledPopUp(mhpspocdata) {
      this.mhp_type = 'called'
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.mhpselectedColoumnSpoc = [];
      this.mhpnetEnquiriesPopup = true;
      this.mhpeditNetEnquiriecount = "";
      this.mhpselectedColoumnSpo = "";
       this.from_spoc = mhpspocdata.id;
       this.from_spoc_name = mhpspocdata.first_name; //full name to first name
       this.mhpeditspoctitle = "MHP Called" + " " + "-" + " " +
       mhpspocdata.first_name +
        ",  " +
        mhpspocdata.city +
        ",    Total : " +
        mhpspocdata.mhp_called;
      console.log("mhpCalledPopUp", mhpspocdata);
    },
    mhpNotCalledPopUp(mhpspocdata) {
      this.mhp_type = 'not_called'
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.mhpselectedColoumnSpoc = [];
      this.mhpnetEnquiriesPopup = true;
      this.mhpeditNetEnquiriecount = "";
      this.mhpselectedColoumnSpo = "";
       this.from_spoc = mhpspocdata.id;
       this.from_spoc_name = mhpspocdata.first_name; //full name to first name
       this.mhpeditspoctitle = "MHP Not Called" + " " + "-" + " " +
       mhpspocdata.first_name +
        ",  " +
        mhpspocdata.city +
        ",    Total : " +
        mhpspocdata.mhp_not_called;
      console.log("mhpNotCalledPopUp", mhpspocdata);
    },
    async assignLead(spoc, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled) {
      console.log('spocsss', spoc);
      this.$vs.loading()
        if(spoc.length === 0){
              setTimeout( ()=> {
              this.$vs.loading.close()
            }, 2000);
        }
        let filtered_spocs = []
        for (let i = 0; i < spoc.length; i++) {
          if(this.spoc_id == 132 ||this.spoc_id == 937 || this.spoc_id == 39 || this.spoc_id == 93 || this.spoc_id == 729){
            filtered_spocs.push(spoc[i]);
          }
          else{
            if(spoc[i].city_classification != 'X'){
              filtered_spocs.push(spoc[i]);
            }
          }
        }
        if(this.spoc_id == 93 || this.spoc_id == 729){
          let xp_account_ids = [3037,3039,3041,3043,3044,3045,3046,3047,3048]
          let filtered_xp = []
            for (let j = 0; j < filtered_spocs.length; j++) {
              const ele = filtered_spocs[j];
              if(ele.city_classification != 'X'){
                filtered_xp.push(ele)
              }
              if(xp_account_ids.includes(ele.id) && ele.city_classification == 'X'){
                filtered_xp.push(ele)
              }
            }
            console.log("filteredResults", filtered_xp);
            for (let i = 0; i < filtered_xp.length; i++) {
            let response = await this.getDashbaordStatsSpoc(filtered_xp[i].id, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled);
              filtered_xp[i].ne_bucket_1 = response.data.ne_bucket_1;
              filtered_xp[i].ne_bucket_2 = response.data.ne_bucket_2;
              filtered_xp[i].mhp_called = response.data.mhp_called;
              filtered_xp[i].mhp_not_called = response.data.mhp_not_called;
            }
            this.$vs.loading.close();
            this.sortSpoc = filtered_xp;
            console.log("sortspoc", this.sortSpoc);
        }else{

          console.log("spoc length not 0" );
          for (let i = 0; i < filtered_spocs.length; i++) {

            console.log("spocs in for lop",filtered_spocs);
            let response = await this.getDashbaordStatsSpoc(filtered_spocs[i].id, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled);

            console.log("getDashbaordStatsSpoc response12345",response);
            // filtered_spocs[i].net_enquiries = response.data.net_enquiries;
              filtered_spocs[i].ne_bucket_1 = response.data.ne_bucket_1;
              filtered_spocs[i].ne_bucket_2 = response.data.ne_bucket_2;
              filtered_spocs[i].mhp_called = response.data.mhp_called;
              filtered_spocs[i].mhp_not_called = response.data.mhp_not_called;

            // filtered_spocs[i].net_enquiries_sorted = this.NetEnquiryaggregator(
            //   response.data.net_enquiries
            // );
          }
          this.$vs.loading.close();
        this.sortSpoc = filtered_spocs;
        console.log("sortspoc", this.sortSpoc);
        }
        

    },
    getDate(date) {
      var datum = Date.parse(date);
      return datum/1000;
    },
    getDashbaordStatsSpoc(spoccount, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled) {
      // let SpocId = spoccount;
      let end_date = '';
      let start_date = '';
      if (
        this.getDate(this.dateRange.startDate) != "Invalid date" && this.dateRange.startDate != '' && this.dateRange.endDate != '' &&
        this.getDate(this.dateRange.endDate) != "Invalid date"
      ) {
        start_date = this.convertToLocalTimestamp(this.dateRange.startDate);
        end_date = this.convertToLocalTimestamp(this.dateRange.endDate);
      }
      let url = `${constants.SERVER_API}getNEForAssignment?spoc_id=${spoccount}&team=${team}&selected_source_b1=${ne_bucket1_source}&selected_source_b2=${ne_bucket2_source}&ne_courses=${selected_course}&start_date=${start_date}&end_date=${end_date}&visit_scheduled=${visit_scheduled}`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
      return promise;
    },
    sortSpocs(value) {
      console.log("value", value);
      this.spocsByCity = [];
      this.allSpocs = [];
      console.log(this.SpocName);
      this.SpocName.forEach((spoc) => {
        // console.log("fsafd", spoc);
        this.allSpocs.push(spoc);
        if (spoc.city === value) {
          this.spocsByCity.push(spoc);
        } else if (spoc.sub_team === value) {
          this.spocsByCity.push(spoc);
        }
      });
      //   this.assignLead(sort);
      console.log("sort", this.spocsByCity);
      this.assignLead(this.spocsByCity, this.selectedTeam, this.ne_bucket1_source, this.ne_bucket2_source, this.selected_course, this.visit_scheduled);
    },
    transferNetEnquiries() {
      this.$vs.loading();
      let obj = {};
      let selected_spocs = [];
        console.log("step1",this.selectedColoumnSpoc);
        if(this.selectedColoumnSpoc.includes('All')){
          for (let i = 0; i < this.selected_usertag_spocs_tree_data[0].children.length; i++) {
            const element = this.selected_usertag_spocs_tree_data[0].children[i];
            selected_spocs.push(element.id);
          }
        }
        if(!this.selectedColoumnSpoc.includes('All')){
          selected_spocs = this.selectedColoumnSpoc;
        }
        let end_date = '';
        let start_date = '';
        if (
          this.getDate(this.dateRange.startDate) != "Invalid date" && this.dateRange.startDate != '' && this.dateRange.endDate != '' &&
          this.getDate(this.dateRange.endDate) != "Invalid date"
        ) {
          start_date = this.convertToLocalTimestamp(this.dateRange.startDate);
        end_date = this.convertToLocalTimestamp(this.dateRange.endDate);
        }
        obj = {
          from_spoc: this.from_spoc,
          to_spoc: selected_spocs,
          count: this.editNetEnquiriecount,
          team: this.selectedTeam,
          ne_bucket: this.ne_bucket,
          ne1_source: "",
          ne2_source: "",
          ne_courses: this.selected_ne_course.join(),
          start_date: start_date,
          end_date: end_date,
          visit_scheduled: this.visit_scheduled
        };
        if(this.selected_ne1_source.length != 0){
          obj.ne1_source = this.selected_ne1_source.join();
        }else if(this.selected_ne2_source.length != 0){
          obj.ne2_source = this.selected_ne2_source.join();
        }
      console.log("net enquiree transfer", obj);
      if (obj.count !== "") {
      let url = `${constants.SERVER_API}reassignNetEnquiryForAdmins`;
      axios
        .post(url, obj, {
        headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
          .then((response) => {
          this.$vs.loading.close();
          console.log(response);
          this.handleNotification(response);
          this.netEnquiriesPopup = false;
          this.sortSpocs(this.selected_city);

        })
          .catch((error) => {
          this.handleError(error);
          });
      } else {
      setTimeout(() => {
      this.$vs.loading.close();
      }, 500);
      this.$vs.notify({
      title: "Require Count",
      text: "Please give count of net enquiries you transfer",
      color: "danger",
      });
      }
    },
    transferMHPNetEnquiries(){
      this.$vs.loading();
      let obj = {};
      let spoc_data = this.getSpocIdsAndNames(this.mhpselectedColoumnSpoc, this.selected_usertag_spocs_tree_data);
      obj = {
        mhp_type: this.mhp_type,
        from_spoc: this.from_spoc,
        from_spoc_name: this.from_spoc_name,
        to_spoc: spoc_data.mhp_selected_spocid_list,
        to_spoc_name: spoc_data.mhp_selected_spoc_firstname_list,
        count: this.mhpeditNetEnquiriecount,
        team: this.selectedTeam,
      };

      console.log("MHP net enquiree transfer", obj);
      if (obj.count !== "") {
        let url = `${constants.SERVER_API}reassignNEMhpsForAdmins`;
        axios
          .post(url, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            this.$vs.loading.close();
            console.log(response);
            this.handleNotification(response);
             this.mhpnetEnquiriesPopup = false;
            this.sortSpocs(this.selected_city);
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
        this.$vs.notify({
          title: "Require Count",
          text: "Please give count of net enquiries you transfer",
          color: "danger",
        });
      }
    },
    getSpocIdsAndNames(selected_spocs, all_spocs){
      let obj ={
       mhp_selected_spoc_firstname_list : [] ,
       mhp_selected_spocid_list : []
      }
      obj.mhp_selected_spoc_firstname_list = [];
      obj.mhp_selected_spocid_list = [];
      if(!selected_spocs.includes('All')){
        for (let i = 0; i < all_spocs[0].children.length; i++) {
          const element = all_spocs[0].children[i];
          for(let j=0; j < selected_spocs.length; j++){
            const ele = selected_spocs[j];
            if(element.id == ele){
              obj.mhp_selected_spoc_firstname_list.push(element.label);
              obj.mhp_selected_spocid_list.push(element.id)
            }
          }
        }
      }
      if(selected_spocs.includes('All')){
         for (let i = 0; i < all_spocs[0].children.length; i++) {
          const element = all_spocs[0].children[i];
              obj.mhp_selected_spoc_firstname_list.push(element.label);
              obj.mhp_selected_spocid_list.push(element.id)
        }
      }
      return obj;
    },
    convertToLocalTimestamp(val){
      var d = new Date(val);
      d.setHours(0,0,0,0);
      return this.getDate(d.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    },
    refreshFilterData(){
      this.dateRange ={
        startDate: '',
        endDate: '',
      }
      this.selected_course = []
      this.ne_bucket1_source = []
      this.ne_bucket2_source = []
      this.visit_scheduled = false
    }
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
.con-vs-popup .vs-popup {
  width: 400px;
}
.assignspocpopupmultiplewidthnet .vs-popup {
  width: 40% !important;
}
.editTxt {
  font-size: 135%;
}
.gap {
  margin-left: 2%;
}
th{
 text-align: center;
}
</style>
